var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import oopsImg from 'app/assets/images/oops.png';
import * as Sentry from '@sentry/browser';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            hasError: false,
        };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, info) {
        Sentry.captureException(error);
        console.log('CAPTURED BY SENTRY');
    };
    ErrorBoundary.prototype.render = function () {
        var hasError = this.state.hasError;
        var children = this.props.children;
        if (hasError) {
            // You can render any custom fallback UI
            return (React.createElement("div", { className: "text-center" },
                React.createElement("img", { src: oopsImg, style: { maxWidth: 200 } }),
                React.createElement("h3", null, "Something went wrong!"),
                React.createElement("p", null, "Its something wrong on our end. We've logged the error and will get on it right away!"),
                React.createElement("p", null, "Sorry about this!")));
        }
        return children;
    };
    return ErrorBoundary;
}(Component));
export default ErrorBoundary;
